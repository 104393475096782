import {
  ProductType,
  TradeOrderType,
  LifecycleEvent,
  LifecycleStatus,
  LifecycleUnofficialStatus,
  InstitutionFeeType,
} from './pb';
import i18n, { getOptionDisplay } from './i18n';
import { getLocalData } from '@easyview/request/lib/store';

// supported products
export const products = {
  [ProductType.PRODUCT_TYPE_FCN]: {
    type: ProductType.PRODUCT_TYPE_FCN,
    name: 'FCN',
    fullName: 'Fixed Coupon Note',
    cnName: '固定票息票据',
  },
  [ProductType.PRODUCT_TYPE_BEN]: {
    type: ProductType.PRODUCT_TYPE_BEN,
    name: 'BEN',
    fullName: 'Bonus Enhanced Note',
    cnName: '收益增强票据',
  },
  [ProductType.PRODUCT_TYPE_ELN]: {
    type: ProductType.PRODUCT_TYPE_ELN,
    name: 'ELN',
    fullName: 'Equity Linked Note',
    cnName: '股票挂钩票据',
  },
  [ProductType.PRODUCT_TYPE_WRA]: {
    type: ProductType.PRODUCT_TYPE_WRA,
    name: 'WRA',
    fullName: 'Worst of Range Accural Note',
    cnName: '区间票息票据',
  },
  [ProductType.PRODUCT_TYPE_STEP_DOWN_FCN]: {
    type: ProductType.PRODUCT_TYPE_STEP_DOWN_FCN,
    name: 'Step-down FCN',
    fullName: 'Step-down Fixed Coupon Note',
    cnName: '敲出价递减型固定派息票据',
  },
  [ProductType.PRODUCT_TYPE_AQ]: {
    type: ProductType.PRODUCT_TYPE_AQ,
    name: 'AQ',
    fullName: 'Accumulator',
    cnName: '累计期权',
  },
  [ProductType.PRODUCT_TYPE_DQ]: {
    type: ProductType.PRODUCT_TYPE_DQ,
    name: 'DQ',
    fullName: 'Decumulator',
    cnName: '累沽期权',
  },
  [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]: {
    type: ProductType.PRODUCT_TYPE_STEP_DOWN_SCN,
    name: 'Step-down SCN',
    fullName: 'Step-down Snowball Coupon Note',
    cnName: '敲出价递减型雪球票据',
  },
  [ProductType.PRODUCT_TYPE_DCN]: {
    type: ProductType.PRODUCT_TYPE_DCN,
    name: 'DCN',
    fullName: 'Digital Coupon Note',
    cnName: '红利票据',
  },
  [ProductType.PRODUCT_TYPE_VAN]: {
    type: ProductType.PRODUCT_TYPE_VAN,
    name: 'VAN',
    fullName: 'Vanilla Option',
    cnName: '普通期权',
  },
  [ProductType.PRODUCT_TYPE_PHOENIX]: {
    type: ProductType.PRODUCT_TYPE_PHOENIX,
    name: 'Phoenix',
    fullName: 'Phoenix Coupon Note',
    cnName: '凤凰期权',
  },
  [ProductType.PRODUCT_TYPE_SHARKFIN]: {
    type: ProductType.PRODUCT_TYPE_SHARKFIN,
    name: 'Sharkfin',
    fullName: 'Sharkfin Note',
    cnName: '鲨鱼鳍型票据',
  },
  [ProductType.PRODUCT_TYPE_PERFORMANCE_PARTICIPATION]: {
    type: ProductType.PRODUCT_TYPE_PERFORMANCE_PARTICIPATION,
    name: 'Performance Participation',
    fullName: 'Performance Participation Note',
    cnName: '表现参与型票据',
  },
  [ProductType.PRODUCT_TYPE_SKYLINE]: {
    type: ProductType.PRODUCT_TYPE_SKYLINE,
    name: 'Skyline',
    fullName: 'Skyline Note',
    cnName: '阶梯型票据',
  },
  [ProductType.PRODUCT_TYPE_FLOATER]: {
    type: ProductType.PRODUCT_TYPE_FLOATER,
    name: 'Floater',
    fullName: 'Cap Floor Floater Note',
    cnName: '浮动利息票据',
  },
  [ProductType.PRODUCT_TYPE_VANILLA_BOND]: {
    type: ProductType.PRODUCT_TYPE_VANILLA_BOND,
    name: 'Fixed/Floating Rate Note',
    fullName: 'Fixed/Floating Rate Note',
    cnName: '固定/浮动利率票据',
  },
  [ProductType.PRODUCT_TYPE_CLN]: {
    type: ProductType.PRODUCT_TYPE_CLN,
    name: 'CLN',
    fullName: 'CLN',
    cnName: '信用挂钩票据',
  },
  [ProductType.PRODUCT_TYPE_AUTOCALL_BOOSTER]: {
    type: ProductType.PRODUCT_TYPE_AUTOCALL_BOOSTER,
    name: 'Autocall Booster',
    fullName: 'Autocall Booster',
    cnName: '敲出型看涨参与型票据',
  },
  [ProductType.PRODUCT_TYPE_BOOSTER]: {
    type: ProductType.PRODUCT_TYPE_BOOSTER,
    name: 'Booster',
    fullName: 'Booster',
    cnName: '看涨参与型票据',
  },
  [ProductType.PRODUCT_TYPE_DRAN]: {
    type: ProductType.PRODUCT_TYPE_DRAN,
    name: 'DRAN',
    fullName: 'DRAN',
    cnName: '区间内每日计息保本票据',
  },
  [ProductType.PRODUCT_TYPE_DRACLN]: {
    type: ProductType.PRODUCT_TYPE_DRACLN,
    name: 'DRACLN',
    fullName: 'DRACLN',
    cnName: '区间内每日计息信用挂钩票据',
  },
  [ProductType.PRODUCT_TYPE_LEVERAGED_STEEPENER]: {
    type: ProductType.PRODUCT_TYPE_LEVERAGED_STEEPENER,
    name: 'Leveraged Steepener',
    fullName: 'Leveraged Steepener',
    cnName: '杠杆型利率曲线陡峭化保本票据',
  },
  [ProductType.PRODUCT_TYPE_TRACKER_CERTIFICATE]: {
    type: ProductType.PRODUCT_TYPE_TRACKER_CERTIFICATE,
    name: 'Tracker Certificate',
    fullName: 'Tracker Certificate',
    cnName: '表现参与型票据',
  },
  [ProductType.PRODUCT_TYPE_OTHER]: {
    type: ProductType.PRODUCT_TYPE_OTHER,
    name: 'Structured Notes(Others)',
    fullName: 'Structured Notes(Others)',
    cnName: '结构化票据(其它类)',
  },
};

// export const currencyOptions = [
//   { value: "USD", label: "USD" },
//   { value: "HKD", label: "HKD" },
//   { value: "GBP", label: "GBP" },
//   { value: "EUR", label: "EUR" },
//   { value: "CNH", label: "CNH" },
// ];
export const currencyOptions = () =>
  // ['USD', 'HKD', 'GBP', 'EUR', 'CNH', 'SGD']
  [
    'USD',
    'HKD',
    'AUD',
    'CAD',
    'JPY',
    'EUR',
    'NZD',
    'SGD',
    'CHF',
    'GBP',
    'CNH',
  ].map((item) => ({
    value: item,
    label: i18n.t(`product.options.currency.${item}`),
  }));
export const fcnKoTypeOptions = () =>
  ['Period End', 'Period End Memory', 'Daily', 'Daily Memory'].map((item) => ({
    value: item,
    label: getOptionDisplay('koType', item),
  }));
export const stepdownSCNKoTypeOptions = () =>
  ['Period End', 'Period End Memory'].map((item) => ({
    value: item,
    label: getOptionDisplay('koType', item),
  }));
export const elnKoTypeOptions = () =>
  ['NA', 'Continuous KO', 'Daily KO'].map((item) => ({
    value: item,
    label: getOptionDisplay('koType', item),
  }));
export const fcnBarrierTypeOptions = () =>
  ['NONE', 'AKI', 'EKI'].map((item) => ({
    value: item,
    label: getOptionDisplay('barrierType', item),
  }));
export const benBarrierTypeOptions = fcnBarrierTypeOptions;
export const dcnBarrierTypeOptions = fcnBarrierTypeOptions;
export const stepdownSCNBarrierTypeOptions = () =>
  ['AKI', 'EKI'].map((item) => ({
    value: item,
    label: getOptionDisplay('barrierType', item),
  }));
export const fcnObservationFrequencyOptions = () => [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '6', label: '6' },
  { value: '12', label: '12' },
];
export const phoenixObservationFrequencyOptions = () => [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '6', label: '6' },
];
export const effectiveDateTypeOptions = () => [
  { value: '1', label: 'Calendar Day', enLabel: 'Calendar Day' },
  { value: '2', label: 'Business Day', enLabel: 'Business Day' },
  { value: '3', label: 'Week', enLabel: 'Week' },
];
export const effectiveDateOffsetOptions = () => [
  { value: '10', label: '10' },
  { value: '5', label: '5' },
];
export const guaranteedPeriodsOptions = () =>
  ['0', '2w', '4w', '6w', '8w', '10w', '12w', '1m', '2m'].map((item) => ({
    value: item,
    label: getOptionDisplay('', item),
  }));
export const normalLeveragedOptions = () =>
  ['Leveraged', 'Normal'].map((item) => ({
    value: item,
    label: getOptionDisplay('normalLeveraged', item),
  }));
export const leverageOptions = [
  { value: '150', label: '150' },
  { value: '200', label: '200' },
];
export const settlementFrequencyOptions = () =>
  ['Biweekly', 'Monthly'].map((item) => ({
    value: item,
    label: getOptionDisplay('settlementFrequency', item),
    enLabel: item,
  }));
export const koSettlementCycleOptions = () =>
  ['Yes', 'No'].map((item) => ({
    value: item,
    label: getOptionDisplay('koSettlementCycle', item),
  }));
// export const optionTypeOptions = [
//   { value: 0, label: 'Call' },
//   { value: 1, label: 'Put' },
// ];
export const optionTypeOptions = () =>
  ['Call', 'Put'].map((item, index) => ({
    value: index,
    label: getOptionDisplay('optionType', item),
  }));

// export const exerciseOptions = [
//   { value: 0, label: 'European' },
//   { value: 1, label: 'American' },
// ];
export const exerciseOptions = (product) =>
  ['European', 'American'].flatMap((item, index) =>
    item === 'American' || product !== ProductType.PRODUCT_TYPE_SHARKFIN
      ? {
          value: index,
          label: getOptionDisplay('exercise', index),
          enLabel: item,
        }
      : []
  );

// export const settlementTypeOptions = [
//   { value: 0, label: 'Cash' },
//   { value: 1, label: 'Physical' },
//   { value: 2, label: 'Fixed' },
//   { value: 3, label: 'Mixed' },
// ];
export const settlementTypeOptions = () =>
  ['Cash', 'Physical', 'Fixed', 'Mixed'].map((item, index) => ({
    value: index,
    label: getOptionDisplay('settlementType', item),
    enLabel: item,
  }));

export const couponTypeOptions = () =>
  ['Memory', 'Non-Memory'].map((item, index) => ({
    value: index + 1,
    label: getOptionDisplay('couponType', index + 1),
    enLabel: item,
  }));

export const directionOptions = (product) =>
  ['TwinWin', 'Bullish', 'Bearish']
    .filter(
      (item) =>
        item === 'TwinWin' || product !== ProductType.PRODUCT_TYPE_SHARKFIN
    )
    .map((item) => ({
      value: item,
      label: getOptionDisplay('direction', item),
    }));

export const formatOptions = (product) =>
  ['Note', 'Warrant']
    .filter(
      (item) => item === 'Note' || product !== ProductType.PRODUCT_TYPE_SHARKFIN
    )
    .map((item) => ({
      value: item,
      label: getOptionDisplay('format', item),
    }));

export const conditionOptions = (product) =>
  ['Capped', 'Floored', 'Capped Floored']
    .filter((item) => item)
    .map((item) => ({
      value: item,
      label: getOptionDisplay('condition', item),
    }));

export const yesOrNoOptions = (key) =>
  ['Yes', 'No'].map((item) => ({
    value: item,
    label: getOptionDisplay(key, item),
  }));

export const numYesOrNoOptions = (key) => [
  {
    value: '1',
    label: getOptionDisplay(key, 'Yes'),
    enLabel: 'Yes',
  },
  {
    value: '2',
    label: getOptionDisplay(key, 'No'),
    enLabel: 'No',
  },
];

export const creditEventSettlementOptions = (key) => [
  {
    value: '1',
    label: getOptionDisplay(key, 'American'),
    enLabel: 'American',
  },
  {
    value: '2',
    label: getOptionDisplay(key, 'European'),
    enLabel: 'European',
  },
];

export const callableFrequencyOptions = () =>
  ['3', '6', '12'].map((item) => ({
    value: item,
    label: item,
  }));

export const yieldMethodOptions = () => [
  {
    value: 1,
    label: getOptionDisplay('yieldMethodEnum', 'p.a.'),
    enLabel: 'p.a.',
  },
  {
    value: 2,
    label: getOptionDisplay('yieldMethodEnum', 'Flat'),
    enLabel: 'Flat',
  },
];
// ["p.a.", "Flat"].map((item, index) => ({
//   value: item,
//   label: getOptionDisplay("yieldMethodEnum", item),
//   enLabel: item,
// }));

export const conditionalRateTypeOptions = () =>
  ['Fixed', 'Floating'].map((item, index) => ({
    value: item,
    label: getOptionDisplay('conditionalRateType', item),
    enLabel: item,
  }));

export const paramPath = (key, ...props) =>
  i18n.t(`product.params.${key}`, ...props);
export const fieldPath = (key, ...props) =>
  i18n.t(`product.field.${key}`, ...props);
export const otherPath = (key, ...props) =>
  i18n.t(`product.others.${key}`, ...props);
export const currency = () => ({
  label: paramPath('currency'),
  enLabel: 'Currency',
  key: 'currency',
  edit: {
    type: 'select',
    options: currencyOptions(),
  },
});
export const underlying = () => ({
  label: paramPath('underlying'),
  enLabel: 'Underlying',
  key: 'underlying',
  edit: {
    type: 'underlying',
    placeholder: (product) => {
      if (
        product === ProductType.PRODUCT_TYPE_ELN ||
        product === ProductType.PRODUCT_TYPE_AQ ||
        product === ProductType.PRODUCT_TYPE_DQ
      ) {
        return i18n.t('product.others.underlyingNum', { context: 'one' });
      } else {
        return i18n.t('product.others.underlyingNum', {
          count: 4,
          context: 'other',
        });
      }
    },
    length: (product) => {
      if (
        product === ProductType.PRODUCT_TYPE_ELN ||
        product === ProductType.PRODUCT_TYPE_AQ ||
        product === ProductType.PRODUCT_TYPE_DQ
      ) {
        return 1;
      } else {
        return 4;
      }
    },
  },
});
export const strike = (type = 0) => ({
  label: paramPath(`strike.${type}`),
  enLabel: 'Strike',
  key: 'strike',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product) => {
    return [
      ProductType.PRODUCT_TYPE_FCN,
      ProductType.PRODUCT_TYPE_ELN,
      ProductType.PRODUCT_TYPE_STEP_DOWN_SCN,
      ProductType.PRODUCT_TYPE_AQ,
      ProductType.PRODUCT_TYPE_DQ,
      ProductType.PRODUCT_TYPE_BEN,
      ProductType.PRODUCT_TYPE_DCN,
      ProductType.PRODUCT_TYPE_PHOENIX,
    ].includes(product);
  },
  edit: {
    type: 'input',
    label: paramPath('strike', { context: `unit.${type}` }),
    disabled: (product, data) => {
      switch (product) {
        case ProductType.PRODUCT_TYPE_SHARKFIN:
          return data?.direction === 'TwinWin';
      }
      return false;
    },
    disabledSave: true,
  },
});
export const koType = () => ({
  label: paramPath('koType'),
  enLabel: 'KO Type',
  key: 'koType',
  edit: {
    type: 'select',
    options: {
      [ProductType.PRODUCT_TYPE_FCN]: fcnKoTypeOptions(),
      [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]: stepdownSCNKoTypeOptions(),
      [ProductType.PRODUCT_TYPE_ELN]: elnKoTypeOptions(),
    },
  },
});
export const koBarrier = () => ({
  label: paramPath('ko'),
  enLabel: 'KO',
  key: 'koBarrier',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('ko', { context: 'unit' }),
    disabled: (product, data) => {
      switch (product) {
        case ProductType.PRODUCT_TYPE_ELN:
          return data?.koType === 'NA';
      }
      return false;
    },
  },
});
export const coupon = () => ({
  label: paramPath('couponPA'),
  enLabel: 'Coupon p.a.',
  key: 'coupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product) => {
    return product === ProductType.PRODUCT_TYPE_FCN;
  },
  edit: {
    type: 'input',
    label: paramPath('couponPA', { context: 'unit' }),
  },
});
export const scnCoupon = () => ({
  label: paramPath('conditionalCouponPA'),
  enLabel: 'Conditional Coupon p.a.',
  key: 'coupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product) => {
    return [
      ProductType.PRODUCT_TYPE_STEP_DOWN_SCN,
      ProductType.PRODUCT_TYPE_PHOENIX,
    ].includes(product);
  },
  edit: {
    type: 'input',
    label: paramPath('conditionalCouponPA', { context: 'unit' }),
  },
});
export const tenor = () => ({
  label: paramPath('tenor'),
  enLabel: 'Tenor',
  key: 'tenor',
  suffix: 'm',
  edit: {
    type: 'input',
    label: paramPath('tenor', { context: 'unit' }),
  },
  type: 'integer',
});
export const barrierType = () => ({
  label: paramPath('barrierType'),
  enLabel: 'Barrier Type',
  key: 'barrierType',
  edit: {
    type: 'select',
    options: {
      [ProductType.PRODUCT_TYPE_FCN]: fcnBarrierTypeOptions(),
      [ProductType.PRODUCT_TYPE_BEN]: benBarrierTypeOptions(),
      [ProductType.PRODUCT_TYPE_DCN]: dcnBarrierTypeOptions(),
      [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]: stepdownSCNBarrierTypeOptions(),
      [ProductType.PRODUCT_TYPE_PHOENIX]: fcnBarrierTypeOptions(),
    },
  },
});
export const kiBarrier = () => ({
  label: paramPath('kiBarrier'),
  enLabel: 'KI Barrier',
  key: 'barrierTypeValue',
  toFixed: 2,
  suffix: '%',
});
export const ki = () => ({
  label: paramPath('ki'),
  enLabel: 'KI',
  key: 'kiBarrier',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('ki', { context: 'unit' }),
    disabled: (product, data) => {
      switch (product) {
        case ProductType.PRODUCT_TYPE_FCN:
        case ProductType.PRODUCT_TYPE_BEN:
        case ProductType.PRODUCT_TYPE_DCN:
        case ProductType.PRODUCT_TYPE_PHOENIX:
          return data?.barrierType === 'NONE';
      }
      return false;
    },
  },
});
export const observationFrequency = () => ({
  label: paramPath('observationFrequency'),
  enLabel: 'Observation Frequency (m)',
  key: 'observationFrequency',
  suffix: 'm',
  edit: {
    type: 'select',
    label: paramPath('observationFrequency', { context: 'unit' }),
    options: {
      [ProductType.PRODUCT_TYPE_FCN]: fcnObservationFrequencyOptions(),
      [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]:
        fcnObservationFrequencyOptions(),
      [ProductType.PRODUCT_TYPE_PHOENIX]: phoenixObservationFrequencyOptions(),
    },
  },
});
export const otc = {
  label: 'OTC',
  key: 'otc',
};

export const fundingSpread = {
  label: 'Funding Spread',
  key: 'fundingSpread',
  suffix: 'bps',
};
export const fcnFundingSpread = {
  label: 'Funding Spread',
  key: 'fundingSpread',
  suffix: 'bps',
  visible: ({ seller }) => {
    return !!seller;
  },
};
export const effectiveDateOffset = () => ({
  label: paramPath('effectiveDateOffset'),
  enLabel: 'Effective Date Offset',
  key: 'effectiveDateOffset',
  edit: {
    type: 'select',
    options: effectiveDateOffsetOptions(),
  },
});
export const elnEffectiveDateOffset = () => ({
  label: paramPath('effectiveDateOffset'),
  enLabel: 'Effective Date Offset',
  key: 'effectiveDateOffset',
  edit: {
    type: 'text',
  },
});
export const issuer = () => ({
  label: paramPath('issuer'),
  enLabel: 'Issuer',
  key: 'issuer',
  visible: ({ share }) => {
    return !!share;
  },
});
export const guaranteedPeriods = () => ({
  label: paramPath('guaranteedPeriods'),
  enLabel: 'Guaranteed Periods',
  key: 'guaranteedPeriods',
  suffix: 'm',
});

export const notePrice = () => ({
  label: paramPath('notePrice'),
  enLabel: 'Note Price',
  key: 'notePrice',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product, others) => {
    return (
      [
        ProductType.PRODUCT_TYPE_FCN,
        ProductType.PRODUCT_TYPE_STEP_DOWN_SCN,
        ProductType.PRODUCT_TYPE_BEN,
        ProductType.PRODUCT_TYPE_DCN,
        ProductType.PRODUCT_TYPE_PHOENIX,
        ProductType.PRODUCT_TYPE_SHARKFIN,
      ].includes(product) &&
      others?.feeType !== InstitutionFeeType.ISSUER_CHARGED
    );
  },
  edit: {
    type: 'input',
    label: paramPath('notePrice', { context: 'unit' }),
  },
  visible: ({
    feeType,
    specialType,
    transactionMode,
    listingVenueType,
    data,
  }) => {
    return !showReofferPriceKey({
      feeType,
      specialType,
      transactionMode,
      listingVenueType,
      data,
    });
  },
});

export const reofferPrice = () => ({
  label: paramPath('reofferPrice'),
  enLabel: 'Reoffer Price',
  key: 'reofferPrice',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product, others) => {
    return (
      [
        ProductType.PRODUCT_TYPE_FCN,
        ProductType.PRODUCT_TYPE_STEP_DOWN_SCN,
        ProductType.PRODUCT_TYPE_BEN,
        ProductType.PRODUCT_TYPE_DCN,
        ProductType.PRODUCT_TYPE_PHOENIX,
        ProductType.PRODUCT_TYPE_SHARKFIN,
        ProductType.PRODUCT_TYPE_ELN,
      ].includes(product) &&
      others?.feeType === InstitutionFeeType.ISSUER_CHARGED
    );
  },
  edit: {
    type: 'input',
    label: paramPath('reofferPrice', { context: 'unit' }),
  },
  visible: ({
    feeType,
    specialType,
    transactionMode,
    listingVenueType,
    data,
  }) => {
    return showReofferPriceKey({
      feeType,
      specialType,
      transactionMode,
      listingVenueType,
      data,
    });
  },
});

export const bonus = () => ({
  label: paramPath('bonus'),
  enLabel: 'Bonus',
  key: 'bonus',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  solveFor: (product) =>
    [ProductType.PRODUCT_TYPE_BEN, ProductType.PRODUCT_TYPE_DCN].includes(
      product
    ),
  type: 'number',
  edit: {
    label: paramPath('bonus', { context: 'unit' }),
  },
});
export const elnYield = () => ({
  label: paramPath('yieldPA'),
  enLabel: 'Yield p.a.',
  key: 'yield',
  // suffix: '%',
});
export const issuePrice2 = () => ({
  label: paramPath('issuePrice'),
  enLabel: 'Issue Price',
  key: 'issuePrice2',
  suffix: '%',
  suffixFor: 'number',
});
export const issuePrice = () => ({
  label: paramPath('notePrice'),
  enLabel: 'Note Price',
  key: 'issuePrice',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  solveFor: (product) => {
    return product === ProductType.PRODUCT_TYPE_ELN;
  },
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('notePrice', { context: 'unit' }),
  },
  visible: ({
    feeType,
    specialType,
    transactionMode,
    listingVenueType,
    data,
  }) => {
    return !showReofferPriceKey({
      feeType,
      specialType,
      transactionMode,
      listingVenueType,
      data,
    });
  },
});
export const issuePriceVan = () => ({
  label: paramPath('issuePrice'),
  enLabel: 'Issue Price',
  key: 'issuePrice',
  suffix: '%',
  toFixed: 2,
  render: (data) => {
    const { bidPrice, offerPrice, uf } = data;
    const rate = data.bidPrice ? -1 : 1;
    return Number(bidPrice || offerPrice) + uf * rate;
  },
});

export const uf = (type = 0) => ({
  label: paramPath(`uf.${type}`),
  enLabel: 'UF',
  key: 'uf',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('uf', { context: `unit.${type}` }),
  },
  visible: ({
    feeType,
    specialType,
    listingVenueType,
    data,
    // individualInfo = {},
  }) => {
    // 旧收费模式是要展示的，但是公域 和 投资点子比较特殊
    const s1 = specialType === 2 && listingVenueType === 1; // 公域
    const s2 = specialType === 1 || data.specialSpread; // 投资点子
    return feeType !== 5 && !s1 && !s2;
  },
});
export const aqdquf = () => ({
  label: paramPath('uf.1'),
  enLabel: 'UF',
  key: 'uf',
  suffix: 'bp',
  suffixFor: 'number',
  toFixed: 2,
  type: 'integer',
  solveFor: (product) => {
    return (
      product === ProductType.PRODUCT_TYPE_AQ ||
      product === ProductType.PRODUCT_TYPE_DQ
    );
  },
  edit: {
    type: 'input',
    label: paramPath('uf', { context: 'unit.1' }),
  },
});
export const issueDate = () => ({
  label: paramPath('issueDate'),
  enLabel: 'Issue Date',
  key: 'issueDate',
  type: 'date',
  edit: {
    type: 'date',
  },
});
export const finalValuationDate = () => ({
  label: paramPath('finalValuationDate'),
  enLabel: 'Final Valuation Date',
  key: 'finalValuationDate',
  type: 'date',
  edit: {
    type: 'date',
  },
});
export const maturityDate = () => ({
  label: paramPath('maturityDate'),
  enLabel: 'Maturity Date',
  key: 'maturityDate',
  type: 'date',
  edit: {
    type: 'date',
  },
});
export const investmentDays = () => ({
  label: paramPath('investmentDays'),
  enLabel: 'Investment Days',
  key: 'investmentDays',
  type: 'integer',
  edit: {
    type: 'text',
  },
});
export const monthlyKOStepdown = () => ({
  label: paramPath('koStepdown'),
  enLabel: 'KO Stepdown',
  key: 'monthlyKoStepDown',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
});
export const koStepdown = () => ({
  label: paramPath('koStepdown'),
  enLabel: 'KO Stepdown',
  key: 'monthlyKoStepDown',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('koStepdown', { context: 'unit' }),
  },
});
export const leveraged = () => ({
  label: paramPath('normalLeveraged'),
  enLabel: 'Normal / Leveraged',
  key: 'normalLeveraged',
});
// export const settlementFrequency = {
//   label: 'Settlement Frequency',
//   key: 'settlementFrequency',
// };
export const settlementCycle = () => ({
  label: paramPath('koSettlementCycle'),
  enLabel: 'KO+1 Settlement Cycle',
  key: 'koSettlementCycle',
  visible: ({ seller }) => {
    return !seller;
  },
});
export const grossMarginToClient = () => {
  const platformKey = getLocalData('platformType') || 0;
  const platform = i18n.t(`platform.${platformKey}`);

  return {
    label: paramPath('grossMargin', { platform }),
    enLabel: 'Gross Margin',
    key: 'grossMarginToClient',
    suffix: '%',
    suffixFor: 'number',
    tipText: new Array(2).fill('').map((_, index) =>
      i18n.t('quotation.detail.grossMarginTip.' + index, {
        platform,
      })
    ),
    visible: ({ feeType, listingVenueType, specialType, data, route }) => {
      const routeArr = ['/quotation/create', '/quotation/detail'];
      // 旧收费模式是要展示的，但是公域 和 投资点子比较特殊
      const s1 = specialType === 2 && listingVenueType === 1; // 公域
      const s2 = specialType === 1 || data.specialSpread; // 投资点子
      const r1 = routeArr.includes(route); // 询价
      return feeType == 5 || s1 || s2 || r1;
    },
    type: 'number',
    edit: {
      type: 'input',
      label: paramPath('grossMargin', { context: 'unit', platform }),
      disabled: (product, data) => {
        switch (product) {
          case ProductType.PRODUCT_TYPE_FCN:
          case ProductType.PRODUCT_TYPE_STEP_DOWN_SCN:
          case ProductType.PRODUCT_TYPE_BEN:
          case ProductType.PRODUCT_TYPE_DCN:
          case ProductType.PRODUCT_TYPE_PHOENIX:
          case ProductType.PRODUCT_TYPE_SHARKFIN:
            return [solveForItem.notePrice, solveForItem.reofferPrice].includes(
              data?.quotationIndex
            );
        }
        return false;
      },
    },
  };
};
export const normalLeveraged = () => ({
  label: paramPath('normalLeveraged'),
  enLabel: 'Normal / Leveraged',
  key: 'normalLeveraged',
  edit: {
    type: 'select',
    options: normalLeveragedOptions(),
  },
});
export const leverageLevel = () => ({
  label: paramPath('leverage'),
  enLabel: 'Leverage',
  key: 'leverageLevel',
  suffix: '%',
  toFixed: '0',
  visible: ({ data }) => {
    if (data) {
      return data?.normalLeveraged === 'Leveraged';
    }
    return true;
  },
  edit: {
    type: 'select',
    label: paramPath('leverage', { context: 'unit' }),
    options: leverageOptions,
    requireDefault: '200',
    requireRely: (product, data) => data?.normalLeveraged === 'Leveraged',
  },
});
// export const guaranteedPeriods = {
//   label: 'Guaranteed Periods',
//   key: 'guaranteedPeriods',
//   edit: {
//     type: 'select',
//     options: guaranteedPeriodsOptions,
//   },
// };
export const aqdqGuaranteedPeriods = () => ({
  label: paramPath('guaranteedPeriods'),
  enLabel: 'Guaranteed Periods',
  key: 'guaranteedPeriods',
  edit: {
    type: 'select',
    options: guaranteedPeriodsOptions(),
  },
});
export const settlementFrequency = () => ({
  label: paramPath('settlementFrequency'),
  enLabel: 'Settlement Frequency',
  key: 'settlementFrequency',
  edit: {
    type: 'select',
    options: settlementFrequencyOptions(),
  },
});
export const koSettlementCycle = () => ({
  label: paramPath('koSettlementCycle'),
  enLabel: 'KO+1 Settlement Cycle',
  key: 'koSettlementCycle',
  edit: {
    type: 'select',
    options: koSettlementCycleOptions().filter(({ value }) => value !== 'No'),
  },
});

export const bidPrice = () => ({
  label: paramPath('bidPrice'),
  enLabel: 'Bid Price',
  key: 'bidPrice',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  visible: ({ data, always }) => always ?? !!data.bidPrice,
});

export const offerPrice = () => ({
  label: paramPath('offerPrice'),
  enLabel: 'Offer Price',
  key: 'offerPrice',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  visible: ({ data, always }) => always ?? !!data.offerPrice,
});

export const optionType = () => ({
  label: paramPath('optionType'),
  enLabel: 'Option Type',
  key: 'optionType',
  render: ({ optionType }) => {
    return (
      optionTypeOptions().find(({ value }) => value === (optionType || 0)) || {}
    ).label;
  },
});

export const exercise = () => ({
  label: paramPath('exercise'),
  enLabel: 'Exercise',
  key: 'exercise',
  render: ({ exercise }) => {
    return exercise || 0;
    // return (
    //   exerciseOptions().find(({ value }) => value === (exercise || 0)) || {}
    // ).label;
  },
});

export const settlementType = () => ({
  label: paramPath('settlementType'),
  enLabel: 'Settlement Type',
  key: 'settlementType',
  render: ({ settlementType }) => {
    return (
      settlementTypeOptions().find(
        ({ value }) => value === (settlementType || 0)
      ) || {}
    ).label;
  },
});

export const couponType = () => ({
  label: paramPath('couponType'),
  enLabel: 'Coupon Type',
  key: 'couponType',
  edit: {
    type: 'select',
    options: couponTypeOptions(),
  },
  render: ({ couponType }) => {
    return (
      couponTypeOptions().find(({ value }) => value === (couponType || 1)) || {}
    ).label;
  },
});

export const couponBarrier = () => ({
  label: paramPath('couponBarrier'),
  enLabel: 'Coupon Barrier',
  key: 'couponBarrier',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('couponBarrier', { context: 'unit' }),
  },
});

export const direction = (product) => ({
  label: paramPath('direction'),
  enLabel: 'Direction',
  key: 'direction',
  edit: {
    type: 'select',
    options: directionOptions(product),
  },
});

export const couponKoType = (product) => ({
  label: paramPath('couponKoType'),
  enLabel: 'Coupon Ko Type',
  key: 'exercise',
  edit: {
    type: 'select',
    options: exerciseOptions(product),
  },
});

export const cashRebate = () => ({
  label: paramPath('cashRebate'),
  enLabel: 'Cash Rebate',
  key: 'cashRebate',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product) => [ProductType.PRODUCT_TYPE_SHARKFIN].includes(product),
  edit: {
    type: 'input',
    label: paramPath('cashRebate', { context: 'unit' }),
  },
});

export const capCoupon = () => ({
  label: paramPath('capCoupon'),
  enLabel: 'Cap Coupon',
  key: 'capCoupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('capCoupon', { context: 'unit' }),
  },
});

export const capCouponPA = () => ({
  label: paramPath('capCouponPA'),
  enLabel: 'Capped Coupon p.a.',
  key: 'capCoupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('capCouponPA', { context: 'unit' }),
  },
});

export const upperBarrier = () => ({
  label: paramPath('upperBarrier'),
  enLabel: 'Upper Barrier',
  key: 'upperBarrier',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  solveFor: (product) => [ProductType.PRODUCT_TYPE_SHARKFIN].includes(product),
  edit: {
    type: 'input',
    label: paramPath('upperBarrier', { context: 'unit' }),
  },
});

export const lowerBarrier = () => ({
  label: paramPath('lowerBarrier'),
  enLabel: 'Lower Barrier',
  key: 'lowerBarrier',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('lowerBarrier', { context: 'unit' }),
    disabled: (product, data) =>
      data.quotationIndex === solveForItem.upperBarrier,
  },
});

export const condition = () => ({
  label: paramPath('condition'),
  enLabel: 'Condition',
  key: 'condition',
  edit: {
    type: 'select',
    options: conditionOptions(),
  },
});

export const issuerCallability = () => ({
  label: paramPath('issuerCallability'),
  enLabel: 'Issuer Callability',
  key: 'issuerCallability',
  edit: {
    type: 'select',
    options: numYesOrNoOptions('issuerCallability'),
  },
});

export const fixedRate = () => ({
  label: paramPath('fixedRate'),
  enLabel: 'Fixed Rate',
  key: 'protectionRate',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('fixedRate', { context: 'unit' }),
  },
});

export const fixedRatePA = () => ({
  label: paramPath('fixedRatePA'),
  enLabel: 'Fixed Rate p.a.',
  key: 'protectionRate',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('fixedRatePA', { context: 'unit' }),
  },
});

export const minimumReturn = () => ({
  label: paramPath('minimumReturn'),
  enLabel: 'Minimum Return',
  key: 'minimumReturn',
  edit: {
    type: 'select',
    options: yesOrNoOptions('minimumReturn'),
    disabled: (product) => product === ProductType.PRODUCT_TYPE_SHARKFIN,
    disabledSave: true,
  },
});

export const floorCoupon = () => ({
  label: paramPath('floorCoupon'),
  enLabel: 'Floor Coupon',
  key: 'floorCoupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('floorCoupon', { context: 'unit' }),
    disabled: (product, data) =>
      data?.quotationIndex === solveForItem.cashRebate,
  },
});

export const floorCouponPA = () => ({
  label: paramPath('floorCouponPA'),
  enLabel: 'Floored Coupon p.a.',
  key: 'floorCoupon',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('floorCouponPA', { context: 'unit' }),
  },
});

export const principalProtection = () => ({
  label: paramPath('principalProtection'),
  enLabel: 'Principal Protection',
  key: 'principalProtection',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('principalProtection', { context: 'unit' }),
  },
});

export const participationRate = () => ({
  label: paramPath('participationRate'),
  enLabel: 'Participation Rate',
  key: 'participation',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  type: 'number',
  edit: {
    type: 'input',
    label: paramPath('participationRate', { context: 'unit' }),
  },
});

export const capReturn = () => ({
  label: paramPath('capReturn'),
  enLabel: 'Cap Return',
  key: 'capReturn',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('capReturn', { context: 'unit' }),
  },
});
export const nonCallPeriod = () => ({
  label: paramPath('nonCallPeriod'),
  enLabel: 'Non-call Period',
  key: 'nonCallPeriod',
  suffix: 'm',
  type: 'number',
  edit: {
    type: 'number',
    label: paramPath('nonCallPeriod', { context: 'unit' }),
  },
});

export const callableFrequency = () => ({
  label: paramPath('callableFrequency'),
  enLabel: 'Callable Frequency',
  key: 'callableFrequency',
  suffix: 'm',
  type: 'integer',
  edit: {
    type: 'select',
    options: callableFrequencyOptions(),
  },
});

export const couponPaymentFrequency = () => ({
  label: paramPath('couponPaymentFrequency'),
  enLabel: 'Coupon Payment Frequency',
  key: 'couponPaymentFrequency',
  suffix: 'm',
  suffixFor: 'number',
  // type: 'integer',
  edit: {
    type: 'select',
    options: callableFrequencyOptions(),
  },
  render: (data) => {
    const value = data.couponPaymentFrequency;
    const path = `product.options.paymentFrequency.${value}`;
    const hasTrans = i18n.exists(path);
    return hasTrans ? i18n.t(path) : +value;
  },
});

export const spreadPA = () => ({
  label: paramPath('spreadPA'),
  enLabel: 'Spread p.a.',
  key: 'spreadPercentage',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: paramPath('spreadPA', { context: 'unit' }),
  },
});

export const spread = () => ({
  label: fieldPath('spreadRate'),
  enLabel: 'Spread Rate',
  key: 'spread',
  suffix: '%',
  suffixFor: 'number',
  toFixed: 2,
  edit: {
    type: 'input',
    label: fieldPath('spreadRate', { context: 'unit' }),
  },
});

// export const spreadPercentage = () => ({
//   label: paramPath("spreadPA"),
//   enLabel: "Spread p.a.",
//   key: "spreadPercentage",
//   suffix: "%",
//   suffixFor: "number",
//   toFixed: 2,
//   edit: {
//     type: "input",
//     label: paramPath("spreadPA", { context: "unit" }),
//   },
// });

export const combineUpperLower = () => ({
  label: paramPath('upper/lowerBarrier'),
  render: (data) => {
    const { upperBarrier, lowerBarrier } = data;
    const upper = upperBarrier ? Number(upperBarrier).toFixed(2) + '%' : '--';
    const lower = lowerBarrier ? Number(lowerBarrier).toFixed(2) + '%' : '--';
    return `${upper}/${lower}`;
  },
});

export const combineCapFloor = () => ({
  label: paramPath('cap/floorCoupon'),
  render: (data) => {
    const { capCoupon, floorCoupon } = data;
    const cap = capCoupon ? Number(capCoupon).toFixed(2) + '%' : '--';
    const lower = floorCoupon ? Number(floorCoupon).toFixed(2) + '%' : '--';
    return `${cap}/${lower}`;
  },
});

export const combineCapFloorPA = () => ({
  label: paramPath('cap/floorCouponPA'),
  render: (data) => {
    const { capCoupon, floorCoupon } = data;
    const cap = capCoupon ? Number(capCoupon).toFixed(2) + '%' : '--';
    const lower = floorCoupon ? Number(floorCoupon).toFixed(2) + '%' : '--';
    return `${cap}/${lower}`;
  },
});

export const issuerQuoteId = () => ({
  label: paramPath('issuerQuoteID'),
  enLabel: 'Issuer QuoteID',
  key: 'issuerQuoteId',
});
export const issuerName = () => ({
  label: paramPath('issuer'),
  enLabel: 'Issuer',
  key: 'issuerName',
});
export const totalDays = () => ({
  label: paramPath('totalDays'),
  enLabel: 'Total Days',
  key: 'totalDays',
});
export const minmaxSizeCell = () => ({
  label: paramPath('minMaxSize'),
  enLabel: 'Min/Max Size',
  key: 'minmaxSize',
});
export const guaranteedDays = () => ({
  label: paramPath('guaranteedDays'),
  enLabel: 'Guaranteed Days',
  key: 'guaranteedDays',
});
export const format = (product) => ({
  label: paramPath('format'),
  enLabel: 'Format',
  key: 'format',
  edit: {
    type: 'select',
    options: formatOptions(product),
  },
});

export const knockoutDesc = () => ({
  // label: 'Knockout',
  label: fieldPath('knockDescription'),
  enLabel: 'Knockout',
  key: 'knockDescription',
});
export const assetDesc = () => ({
  label: fieldPath('assetsDescription'),
  enLabel: 'Reference Entity',
  key: 'assetDescription',
});
export const returnDesc = () => ({
  label: fieldPath('returnDescription'),
  enLabel: 'Return Description',
  key: 'returnDescription',
});
export const protectionDesc = () => ({
  label: fieldPath('protectionDescription'),
  enLabel: 'Protection',
  key: 'protectionDescription',
});
export const referenceObligation = () => ({
  label: fieldPath('referenceObligation'),
  enLabel: 'Reference Obligation',
  key: 'referenceObligation',
});
export const creditEvents = () => ({
  label: fieldPath('creditEvents'),
  enLabel: 'Credit Events',
  key: 'creditEvents',
});

export const creditEventSettlement = () => ({
  label: fieldPath('creditEventSettlement'),
  enLabel: 'Credit Event Settlement',
  key: 'creditEventSettlement',
  edit: {
    type: 'select',
    options: creditEventSettlementOptions(),
  },
});
export const paymentFrequency = () => ({
  label: fieldPath('paymentFrequency'),
  enLabel: 'Payment Frequency',
  key: 'couponPaymentFrequency',
  suffix: 'm',
});

export const yieldMethod = () => ({
  label: fieldPath('yieldMethod'),
  enLabel: 'Yield Method',
  key: 'yieldMethod',
  edit: {
    type: 'select',
    options: yieldMethodOptions('yieldMethod'),
  },
});
export const conditionalRateType = () => ({
  label: fieldPath('conditionalRateType'),
  enLabel: 'Conditional Rate Type',
  key: 'condition',
  edit: {
    type: 'select',
    options: conditionalRateTypeOptions(),
  },
});

export const benchmark = () => ({
  label: fieldPath('benchmark'),
  enLabel: 'Benchmark',
  key: 'benchmark',
});
export const remark = () => ({
  label: fieldPath('remark'),
  enLabel: 'Remark',
  key: 'remark',
});
export const lowerCallStrike = () => ({
  label: fieldPath('lowerCallStrike'),
  enLabel: 'Lower Call Strike',
  key: 'lowerCallStrike',
  suffix: '%',
});

export const upperCallStrike = () => ({
  label: fieldPath('upperCallStrike'),
  enLabel: 'Upper Call Strike',
  key: 'upperCallStrike',
  suffix: '%',
});
export const minimumRedemption = () => ({
  label: fieldPath('minimumRedemption'),
  enLabel: 'Minimum Redemption',
  key: 'minimumRedemption',
  suffix: '%',
});
export const conditionalCoupon = () => ({
  label: fieldPath('conditionalCoupon'),
  enLabel: 'Conditional Coupon',
  key: 'conditionalCoupon',
});
export const putStrike = () => ({
  label: fieldPath('putStrike'),
  enLabel: 'Put Strike',
  key: 'putStrike',
  suffix: '%',
});
export const dailyAccrualCondition = () => ({
  label: fieldPath('dailyAccrualCondition'),
  enLabel: 'Daily Accrual Condition',
  key: 'dailyAccrualCondition',
});
export const fixedCouponClause = () => ({
  label: fieldPath('fixedCouponClause'),
  enLabel: 'Fixed Coupon Clause',
  key: 'fixedCouponClause',
  edit: {
    type: 'select',
    options: numYesOrNoOptions('fixedCouponClause'),
  },
});
export const fixedCoupon = () => ({
  label: fieldPath('fixedCoupon'),
  enLabel: 'Fixed Coupon',
  key: 'fixedCoupon',
  suffix: '%',
});

export const fixedCouponPeriod = () => ({
  label: fieldPath('fixedCouponPeriod'),
  enLabel: 'Fixed Coupon Period',
  key: 'fixedCouponPeriod',
  suffix: 'm',
});
export const referenceEntity = () => ({
  label: fieldPath('referenceEntity'),
  enLabel: 'Reference Entity',
  key: 'assetDescription',
});
export const leverageFactor = () => ({
  label: fieldPath('leverageFactor'),
  enLabel: 'Leverage Factor',
  key: 'leverageFactor',
  suffix: '%',
});
export const stopLoss = () => ({
  label: fieldPath('stopLoss'),
  enLabel: 'Stop Loss',
  key: 'stopLoss',
  edit: {
    type: 'select',
    options: numYesOrNoOptions('stopLoss'),
  },
});
export const stopLossTrigger = () => ({
  label: fieldPath('stopLossTrigger'),
  enLabel: 'Stop Loss Trigger',
  key: 'stopLossTrigger',
  suffix: '%',
});
export const managementFee = () => ({
  label: fieldPath('managementFee'),
  enLabel: 'Management Fee',
  key: 'managementFee',
  suffix: '%',
});
export const performanceFee = () => ({
  label: fieldPath('performanceFee'),
  enLabel: 'Performance Fee',
  key: 'performanceFee',
  suffix: '%',
});
export const generalTerm = () => ({
  label: fieldPath('generalTerm'),
  enLabel: 'General Term',
  key: 'generalTerm',
});
export const effectiveDateType = () => ({
  label: fieldPath('effectiveDateType'),
  enLabel: 'Effective Date Type',
  key: 'effectiveDateType',
  edit: {
    type: 'select',
    options: effectiveDateTypeOptions(),
  },
});

// export const spreadRate = ()=>({})
export const quotationSolveForMap = () => ({
  1: strike(),
  2: koBarrier(),
  3: coupon(),
  'stepdown-scn-3': scnCoupon(), // just for create
  4: bonus(),
  5: notePrice(),
  6: uf(),
  7: issuePrice(),
  8: tenor(),
  9: koType(),
  10: barrierType(),
  11: ki(),
  12: observationFrequency(),
  13: currency(),
  14: effectiveDateOffset(),
  30: bidPrice(),
  31: offerPrice(),
  37: upperBarrier(),
  39: cashRebate(),
  45: reofferPrice(),
});

export const solveForItem = {
  strike: 1,
  koBarrier: 2,
  coupon: 3,
  bonus: 4,
  notePrice: 5,
  uf: 6,
  issuePrice: 7,
  tenor: 8,
  koType: 9,
  barrierType: 10,
  kiBarrier: 11,
  observationFrequency: 12,
  currency: 13,
  effectiveDateOffset: 14,
  bidPrice: 30,
  offerPrice: 31,
  upperBarrier: 37,
  cashRebate: 39,
  reofferPrice: 45,
};

export const quotationResultRange = {
  [strike().key]: [40, 100],
  ['eln' + strike().key]: [85, 100],
  ['dq' + strike().key]: [100, 160],
  ['vanCall' + strike().key]: [40, 150],
  ['vanPut' + strike().key]: [40, 110],
  [bonus().key]: [0.01, 100],
  [coupon().key]: [0.01, 100],
  [notePrice().key]: [90, 100],
  [reofferPrice().key]: [90, 100],
  [koBarrier().key]: [60, 999],
  ['aq' + koBarrier().key]: [102, 999],
  ['dq' + koBarrier().key]: [60, 98],
  [koStepdown().key]: [0, 10],
  ['aqdq' + uf().key]: [0, 250],
  [uf().key]: [0, 250],
  [tenor().key]: [1, 60],
  [ki().key]: [40, 100],
  [issuePrice().key]: [80, 100],
  ['eln' + uf().key]: [0, 5],
  ['eln' + koBarrier().key]: [102, 999],
  ['eln' + grossMarginToClient().key]: [0, 5],
  [bidPrice().key]: [0, 100],
  [offerPrice().key]: [0, 100],
  [couponBarrier().key]: [0.01, 120],
  [grossMarginToClient().key]: [0, 10],
  [upperBarrier().key]: [101, 999],
  [lowerBarrier().key]: [60, 100],
  [cashRebate().key]: [0, 100],
  [floorCoupon().key]: [0, 100],
  ['sharkfin' + notePrice().key]: [80, 100],
  ['sharkfin' + reofferPrice().key]: [80, 100],
  ['sharkfin' + tenor().key]: [1, 240],
  ['sharkfin' + grossMarginToClient().key]: [0, 20],
  [principalProtection().key]: [50, 150],
  [participationRate().key]: [0, 150],
};

const optionTypePath = (key) => i18n.t(`trade.create.orderTypeOption.${key}`);

export const tradeOrderTypeMap = () => ({
  [TradeOrderType.TRADE_ORDER_TYPE_MARKET_PRICE]: optionTypePath('marketPrice'),
  [TradeOrderType.TRADE_ORDER_TYPE_LIMIT_PRICE]: optionTypePath('limitPrice'),
  [TradeOrderType.TRADE_ORDER_TYPE_MARKET_CLOSE]: optionTypePath('marketClose'),
  [TradeOrderType.TRADE_ORDER_TYPE_VWAP]: optionTypePath('vwap'),
  [TradeOrderType.TRADE_ORDER_TYPE_TWAP]: optionTypePath('twap'),
  [TradeOrderType.TRADE_ORDER_TYPE_APOC]: optionTypePath('averagedPrice'),
});

export const orderTypeOptions = () => [
  {
    label: i18n.t('trade.create.orderTypeOption.marketPrice'),
    value: TradeOrderType.TRADE_ORDER_TYPE_MARKET_PRICE,
    enLabel: 'Market Price',
  },
  {
    label: i18n.t('trade.create.orderTypeOption.limitPrice'),
    value: TradeOrderType.TRADE_ORDER_TYPE_LIMIT_PRICE,
    enLabel: 'Limit Price',
  },
  {
    label: i18n.t('trade.create.orderTypeOption.marketClose'),
    value: TradeOrderType.TRADE_ORDER_TYPE_MARKET_CLOSE,
    enLabel: 'Market Close',
  },
  {
    label: i18n.t('trade.create.orderTypeOption.vwap'),
    value: TradeOrderType.TRADE_ORDER_TYPE_VWAP,
    enLabel: 'VWAP',
  },
  {
    label: i18n.t('trade.create.orderTypeOption.twap'),
    value: TradeOrderType.TRADE_ORDER_TYPE_TWAP,
    enLabel: 'TWAP',
  },
  {
    label: i18n.t('trade.create.orderTypeOption.averagedPrice'),
    value: TradeOrderType.TRADE_ORDER_TYPE_APOC,
    enLabel: 'Averaged Price of Open + Close',
  },
];

export const newsType = {
  NONE: 0,
  MARKET: 1,
  PRODUCT: 2,
  LIFE: 3,
  INSTRUCTURE: 4,
  BANK: 5,
  IB_PERSPERCTIVES: 14,
  PB_PERSPERCTIVES: 16,
};

export const feeTypeMap = {
  0: 'None',
  1: 'Fixed Spread',
  2: 'UF Sharing',
  3: 'Platform',
  4: 'One-off',
  5: 'Issuer Charged',
};

// lcm

// 存续列表搜索条件 - 最新事件
export const lcmLatestEventOptions = () => {
  const filterVals = [LifecycleEvent.LCE_KI, LifecycleEvent.LCE_STRIKE];
  return latestEventOptions().filter((l) => !filterVals.includes(l.value));
};
// 存续最新事件
export const latestEventOptions = () => {
  return [
    // {
    //   label: 'Latest Event',
    //   value: 0,
    // },
    // {
    //   label: `Unknow`,
    //   value: LifecycleEvent.LCE_UNKNOWN,
    // },
    {
      label: i18n.t('lcm.knockOutOb'),
      value: LifecycleEvent.LCE_EARLY_AUTO_CALL_OBSERVATION,
    },
    {
      label: i18n.t('lcm.finalFixingOb'),
      value: LifecycleEvent.LCE_FINAL_FIXING,
    },
    {
      label: i18n.t('lcm.couponPaymentOb'),
      value: LifecycleEvent.LCE_COUPON_PAYMENT,
    },
    {
      label: i18n.t('lcm.finalSettlementOb'),
      value: LifecycleEvent.LCE_FINAL_SETTLEMENT,
    },
    {
      label: i18n.t('lcm.settlemetOb'),
      value: LifecycleEvent.LCE_SETTLEMENT,
    },
    {
      label: i18n.t('lcm.couponBarrierOb'),
      value: LifecycleEvent.LCE_COUPON_BARRIER,
    },
    {
      label: i18n.t('lcm.kiOb'),
      value: LifecycleEvent.LCE_KI,
    },
    {
      label: i18n.t('lcm.strikeOb'),
      value: LifecycleEvent.LCE_STRIKE,
    },
  ];
};
// 存续列表搜索条件
export const lcmStatusSearchOptions = () => {
  const filterVals = [
    LifecycleStatus.LIFECYCLE_STATUS_UNWIND,
    LifecycleStatus.LIFECYCLE_STATUS_NOT_SUPPORT,
  ];
  return lifecycleStatusOptions().filter((l) => !filterVals.includes(l.value));
};

// 存续状态
export const lifecycleStatusOptions = () => {
  return [
    // {
    //   label: 'All',
    //   value: LifecycleStatus.LIFECYCLE_STATUS_ALL,
    // },
    {
      label: i18n.t('lcm.outstanding'),
      value: LifecycleStatus.LIFECYCLE_STATUS_IN_EXISTENCE,
    },
    {
      label: i18n.t('lcm.earlyCalled'),
      value: LifecycleStatus.LIFECYCLE_STATUS_KNOCKED_OUT,
    },
    {
      label: i18n.t('lcm.expired'),
      value: LifecycleStatus.LIFECYCLE_STATUS_EXPIRED,
    },
    {
      label: i18n.t('lcm.terminated'),
      value: LifecycleStatus.LIFECYCLE_STATUS_TERMINATION,
    },
    {
      label: '已卖出',
      value: LifecycleStatus.LIFECYCLE_STATUS_UNWIND,
    },
    {
      label: '暂不支持',
      value: LifecycleStatus.LIFECYCLE_STATUS_NOT_SUPPORT,
    },
  ];
};

// 非官方结果
export const finalFixingOptions = () => {
  //  UNOFFICIAL_STATUS_UNKNOWN = 0;
  // // 敲出观察: 已敲出
  // UNOFFICIAL_STATUS_EARLY_AUTO_CALLED = 1;
  // // 敲出观察: 未敲出
  // UNOFFICIAL_STATUS_NOT_EARLY_AUTO_CALLED = 2;
  // // 已敲入: 现金结算
  // UNOFFICIAL_STATUS_CASH_SETTLEMENT = 3;
  // // 已敲入: 接盘股票
  // UNOFFICIAL_STATUS_PHYSICAL_DELIVERY = 4;
  // // 票息日: 票息支付
  // UNOFFICIAL_STATUS_COUPON_PAYMENT = 5;
  // // 敲出票息
  // UNOFFICIAL_STATUS_KO_COUPON = 6;
  // // 表现票息
  // UNOFFICIAL_STATUS_PERFORMANCE_COUPON = 7;
  return [
    {
      label: 'physicalDelivery',
      value: LifecycleUnofficialStatus.UNOFFICIAL_STATUS_PHYSICAL_DELIVERY,
    },
    {
      label: 'cash',
      value: LifecycleUnofficialStatus.UNOFFICIAL_STATUS_CASH_SETTLEMENT,
    },
  ];
};

// 展示reofferPrice字段
const showReofferPriceKey = ({
  transactionMode,
  specialType,
  feeType,
  listingVenueType,
  data,
}) => {
  const newFeeType = feeType === InstitutionFeeType.ISSUER_CHARGED;
  // const s1 = transactionMode === 1 && newFeeType; // 普通订单 && 新收费
  const s2 = specialType === 2 && listingVenueType === 1; // 商品下单 && 公域
  // const s3 = specialType === 2 && listingVenueType === 2 && newFeeType; // 商品下单 && 私域 && 新收费
  const s4 = specialType === 1 || data?.specialSpread; // 投资点子  && 询价投资点子展示reofferPrice
  // const s5 = specialType === 3 && newFeeType; // 产品库下单
  // console.log('transactionMode', transactionMode);
  // console.log('specialType', specialType);
  // console.log('feeType', feeType);
  // console.log('listingVenueType', listingVenueType);
  // console.log('data', data, newFeeType || s2 || s4);
  return newFeeType || s2 || s4;
};

export const BARRIER_KEY = {
  koBarrier: [1, 5, 6, 7, 8],
  kiBarrier: [1, 5, 8],
  strike: [1, 5, 6, 7, 8],
  upperBarrier: [12, 14],
  lowerBarrier: [12, 14],
};
