import {
  GuaranteedPeriodUnit,
  InstitutionFeeType,
  ProductType,
  getMessage,
  ProductTypePrefix,
} from './pb';
import dayjs from 'dayjs';
import currencyjs from 'currency.js';
import Big from 'big.js';
import {
  solveForItem,
  aqdquf,
  uf as ufObj,
  notePrice as notePriceObj,
  reofferPrice as reofferPriceObj,
  products,
} from './constants';
import { appOpenPage } from './bridge';
import { debounce } from 'lodash';
import packageInfo from '@/package.json';
import i18n, { getOptionDisplay } from './i18n';
import pica from 'pica';
import { isClient } from '@/utils/core';
import CryptoJS from 'crypto-js';

export const ACCOUNT_TYPE = {
  ADMIN: 0, // 系统管理员
  DISTRIBUTION: 1, // 分销商
  EAM: 2, // 机构
  INDIVIDUAL: 3, // 个人
};
export const DISTRIBUTION_TYPE = {
  ADMIN: 1, // 分销商管理员
  INTERNAL_MEMBER: 2, // 分销商内部成员
  OUTSIDE_MEMBER: 3, // 分销商外部成员
};

// 金证引导开户h5页面
// export const GOLD_URL = 'https://evguide.fwdev.top'; TEST
export const GOLD_URL = 'https://evguide.igoldhorse.com';

// 个人用户非持牌认证的屏蔽部分字段
export const INDIVIDUAL_PARAMS = [
  'notePrice',
  'uf',
  'upFront',
  'issuePrice',
  'grossMarginToClient',
  'reofferPrice',
  'issuerInfo',
  'issuerQuoteId',
];
// 个人用户无脑屏蔽部分字段
export const INDIVIDUAL_PRO_PARAMS = ['grossMarginToClient', 'issuerQuoteId'];

export function parseAccountId(id) {
  if (!id) return {};
  return {
    accountType: parseInt(id / 10000000000),
    institutionId: parseInt((id % 10000000000) / 1000000),
    innerId: parseInt(id % 1000000),
  };
}

export const newsLangMap = {
  //繁体和简体都是中文
  tc: 1,
  zh: 1,
  en: 2,
};

// format product params
const guaranteedPeriodUnitMap = {
  [GuaranteedPeriodUnit.UNIT_MONTH]: 'm',
  [GuaranteedPeriodUnit.UNIT_WEEK]: 'W',
};
export function formatData({ originData, data, setting }) {
  let ret = setting.render?.(originData) || data;
  if (
    isELN(originData.type) &&
    setting.key === 'grossMarginToClient' &&
    !originData.grossMarginToClient
  ) {
    if (originData.reofferPrice) {
      let reo = originData.reofferPrice;
      if (typeof originData.reofferPrice != 'string') {
        reo = originData.reofferPrice.value;
      }
      if (isNaN(originData.issuePrice2) && isNaN(originData.issuePrice)) {
        return '--';
      }
      const spread = Math.abs(
        minus(
          parseFloat(originData.issuePrice2 || originData.issuePrice),
          parseFloat(reo)
        )
      );
      return spread;
    }
  }
  if (!ret) {
    ret = '--';
  } else {
    if (setting.dateFormat) {
      ret = dayjs(ret).format(setting.dateFormat);
    }
    if (setting.toFixed && !isNaN(ret)) {
      ret = (+ret).toFixed(setting.toFixed);
    } else if (setting.type === 'integer' && !isNaN(ret)) {
      ret = parseInt(ret);
    }
    if (setting.suffix) {
      if (setting.suffixFor) {
        if (
          typeof ret === setting.suffixFor ||
          (setting.suffixFor === 'number' && !isNaN(ret))
        ) {
          ret = ret + setting.suffix;
        }
      } else {
        ret = ret + setting.suffix;
      }
    }
    if (setting.key === 'guaranteedPeriods') {
      if (+data && originData) {
        ret = data + guaranteedPeriodUnitMap[originData.gpUnit || 0];
      } else {
        ret = data;
      }
    }
    // if (setting.key === "issuerCallability") {
    //   ret = isNaN(+data) ? data : +data === 1 ? "Yes" : "No";
    // }
  }
  return getOptionDisplay(setting.key, ret);
}

// format time
// > 24H, YYYY/MM/DD hh:mm A
// <= 24H, hh:mm A
const oneDayHour = 1000 * 60 * 60 * 24;
export function formatTime(time) {
  if (!time) return time;
  const t = dayjs(+time);
  const currentTime = Date.now();
  if (currentTime - time > oneDayHour) {
    return t.format('YYYY/MM/DD hh:mm A');
  } else {
    return t.format('hh:mm A');
  }
}

// calc gross margin to client
export function calcGrossMarginToClient({
  product,
  feeType,
  spread,
  notePrice,
  reofferPrice,
  uf,
  grossMarginToClient,
  reverse = false,
}) {
  if (!feeType) return;
  let ret = '';
  let retKey = '';
  if (product === ProductType.PRODUCT_TYPE_ELN) {
    if (!reverse) {
      if (!uf || isNaN(uf)) return;
      if (feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_FIXED_SPREAD) {
        ret = currencyjs(uf).subtract(spread);
      } else if (
        feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_UF_SHARING
      ) {
        ret = currencyjs(uf)
          .multiply(currencyjs(100).subtract(spread))
          .divide(100);
      } else if (
        [
          InstitutionFeeType.COST_MODE_PLATFORM,
          InstitutionFeeType.COST_MODE_ONE_OFF,
        ].includes(feeType)
      ) {
        ret = currencyjs(uf);
      }
    } else {
      if (!grossMarginToClient || isNaN(grossMarginToClient)) return;
      retKey = ufObj().key;
      if (feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_FIXED_SPREAD) {
        ret = currencyjs(grossMarginToClient).add(spread);
      } else if (
        feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_UF_SHARING
      ) {
        // const temp = currencyjs(100).subtract(spread);
        // ret = currencyjs(grossMarginToClient).divide(temp).multiply(100);
        ret = (grossMarginToClient / (100 - spread)) * 100;
      } else if (
        [
          InstitutionFeeType.COST_MODE_PLATFORM,
          InstitutionFeeType.COST_MODE_ONE_OFF,
        ].includes(feeType)
      ) {
        ret = currencyjs(grossMarginToClient);
      } else if (InstitutionFeeType.ISSUER_CHARGED) {
        retKey = '';
      }
    }
  } else if (
    product === ProductType.PRODUCT_TYPE_AQ ||
    product === ProductType.PRODUCT_TYPE_DQ
  ) {
    if (!reverse) {
      if (!uf || isNaN(uf)) return;
      ret = currencyjs(uf).divide(100);
    } else {
      if (!grossMarginToClient || isNaN(grossMarginToClient)) return;
      retKey = aqdquf().key;
      ret = currencyjs(grossMarginToClient).multiply(100);
    }
  } else {
    if (!reverse) {
      if (
        ((!notePrice || isNaN(notePrice)) &&
          ![InstitutionFeeType.ISSUER_CHARGED].includes(feeType)) ||
        ((!reofferPrice || isNaN(reofferPrice)) &&
          [InstitutionFeeType.ISSUER_CHARGED].includes(feeType))
      )
        return;
      if (feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_FIXED_SPREAD) {
        ret = currencyjs(100).subtract(notePrice).subtract(spread);
      } else if (
        feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_UF_SHARING
      ) {
        // ret = currencyjs(100).subtract(spread);
        ret = currencyjs(100)
          .subtract(notePrice)
          .multiply(currencyjs(100).subtract(spread))
          .divide(100);
      } else if (
        [
          InstitutionFeeType.COST_MODE_PLATFORM,
          InstitutionFeeType.COST_MODE_ONE_OFF,
        ].includes(feeType)
      ) {
        ret = currencyjs(100).subtract(notePrice);
      } else if (feeType === InstitutionFeeType.ISSUER_CHARGED) {
        ret = currencyjs(100).subtract(reofferPrice);
      }
    } else {
      if (!grossMarginToClient || isNaN(grossMarginToClient)) return;
      retKey = notePriceObj().key;
      if (feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_FIXED_SPREAD) {
        ret = currencyjs(100).subtract(grossMarginToClient).subtract(spread);
      } else if (
        feeType === InstitutionFeeType.INSTITUTION_FEE_TYPE_UF_SHARING
      ) {
        ret = 100 - grossMarginToClient / (1 - spread / 100);
      } else if (
        [
          InstitutionFeeType.COST_MODE_PLATFORM,
          InstitutionFeeType.COST_MODE_ONE_OFF,
        ].includes(feeType)
      ) {
        ret = currencyjs(100).subtract(grossMarginToClient);
      } else if (feeType === InstitutionFeeType.ISSUER_CHARGED) {
        retKey = reofferPriceObj().key;
        ret = currencyjs(100).subtract(grossMarginToClient);
      }
    }
  }
  if (retKey) {
    return {
      [retKey]: String(ret?.value ?? ret),
    };
  }
  return String(ret?.value);
}

// get quotation best pricer
export function getQuotationBestPricer(data, issuers = {}) {
  const quotationIssuers = data.info?.content?.issuerInfos ?? [];
  const records = data.info?.content?.records ?? [];
  return records.map((record, index) => {
    if (!record.issuerId) return;
    const bestPricerId = record.issuerId;
    const result = quotationIssuers.find(
      (issuer) => issuer.issuerId === bestPricerId
    )?.issuerResults?.[index]?.result;
    return {
      result,
      issuer: issuers[bestPricerId],
    };
  });
}

// calc quotation best pricer
// 1（正数）为越高越好，-1（负数）为越低越好，其余满足条件的为越高越好
const bestPricerRule = {
  [ProductType.PRODUCT_TYPE_FCN]: {
    [solveForItem.strike]: -1,
    [solveForItem.koBarrier]: -1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.coupon]: 1,
  },
  [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]: {
    [solveForItem.strike]: -1,
    [solveForItem.koBarrier]: -1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.coupon]: 1,
  },
  [ProductType.PRODUCT_TYPE_STEP_DOWN_FCN]: {
    [solveForItem.strike]: -1,
    [solveForItem.koBarrier]: -1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.coupon]: 1,
  },
  [ProductType.PRODUCT_TYPE_WRA]: {
    [solveForItem.strike]: -1,
    [solveForItem.koBarrier]: -1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.coupon]: 1,
  },
  [ProductType.PRODUCT_TYPE_ELN]: {
    [solveForItem.issuePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.strike]: -1,
  },
  [ProductType.PRODUCT_TYPE_BEN]: {
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.bonus]: 1,
  },
  [ProductType.PRODUCT_TYPE_DCN]: {
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.bonus]: 1,
  },
  [ProductType.PRODUCT_TYPE_AQ]: {
    [solveForItem.strike]: -1,
    [solveForItem.uf]: 1,
  },
  [ProductType.PRODUCT_TYPE_DQ]: {
    [solveForItem.strike]: 1,
    [solveForItem.uf]: 1,
  },
  [ProductType.PRODUCT_TYPE_VAN]: {
    [solveForItem.strike]: { optionType: [0] },
    [solveForItem.bidPrice]: 1,
    [solveForItem.offerPrice]: -1,
  },
  [ProductType.PRODUCT_TYPE_PHOENIX]: {
    [solveForItem.strike]: -1,
    [solveForItem.koBarrier]: -1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
    [solveForItem.coupon]: 1,
  },
  [ProductType.PRODUCT_TYPE_SHARKFIN]: {
    [solveForItem.upperBarrier]: 1,
    [solveForItem.cashRebate]: 1,
    [solveForItem.notePrice]: -1,
    [solveForItem.reofferPrice]: -1,
  },
};

// calc best pricer
// 0: all
// -1: applicable
export const CUSTODIANS_ALL = 0;
export const CUSTODIANS_APPLICABLE = -1;
export const CUSTODIANS_BING = -2;
export function calcQuotationBestPricer({ data, applicable, custodians }) {
  let quotationIssuers = data.info?.content?.issuerInfos ?? [];
  const quotationCustodians = data.info?.content?.custodianIds ?? [];
  const quoCustodian = data.info?.content?.custodian ?? [];
  // get applicable issuers
  let allIssuers = [];
  if (applicable === CUSTODIANS_APPLICABLE) {
    const arr = quotationCustodians.map(
      (item) => quoCustodian.find((q) => q.custodianId === item)?.issuerId || []
    );
    // const arr = quoCustodian.map((q) => q.issuerId);
    allIssuers = _.intersection(...arr);
    quotationIssuers = quotationIssuers.filter((d) => {
      return allIssuers.includes(d.issuerId);
    });
  } else if (applicable === CUSTODIANS_BING) {
    quoCustodian.forEach((d) => {
      allIssuers = allIssuers.concat(d?.issuerId);
    });
    quotationIssuers = quotationIssuers.filter((d) => {
      return allIssuers.includes(d.issuerId);
    });
  } else if (applicable > 0) {
    const ids =
      quoCustodian?.find((q) => q.custodianId === applicable)?.issuerId ?? [];
    // const ids = custodians[applicable]?.issuerId;
    quotationIssuers = quotationIssuers.filter((d) => ids.includes(d.issuerId));
  }
  const records = data.info?.content?.records ?? [];
  return records.map((record, index) => {
    let temp = bestPricerRule[data.info?.type]?.[record.quotationIndex];

    let flag = 1;
    if (_.isPlainObject(temp)) {
      const keysArr = Object.keys(temp); //['optionType']
      keysArr.forEach((key) => {
        if (!temp[key].includes(record.parameter[key] ?? 0)) {
          flag = -1;
          return;
        }
      });
    }
    const rule = typeof temp === 'number' ? temp : flag;

    let selected;
    quotationIssuers.forEach((d) => {
      // 被撤销价格不参与计算
      if (
        d.issuerResults?.[index].cancelled ||
        d.issuerResults?.[index]?.result === ''
      ) {
        return;
      }
      // 当前询价项issuer返回的报价
      const { result, lowerBarrier } = d.issuerResults?.[index];
      let v = result;
      if (!v) return;
      if (!selected) {
        selected = d;
        return;
      }
      v = +v;
      let selectedV = +selected.issuerResults?.[index]?.result;
      if (isSharkfin(data.info?.type) && record.quotationIndex === 37) {
        const newLb = lowerBarrier || record.parameter?.lowerBarrier;
        const oldLb =
          selected.issuerResults?.[index]?.lowerBarrier ||
          record.parameter?.lowerBarrier;
        v = Math.abs(minus(+result || 0, +newLb || 0));
        selectedV = Math.abs(minus(+selectedV || 0, +oldLb || 0));
      }
      if (rule < 0) {
        selected = v <= selectedV ? d : selected;
      } else {
        selected = v >= selectedV ? d : selected;
      }
    });
    return selected;
  });
}

// 保留小数点(非四舍五入)
// n: 待处理的数
// fixed: 保留的位数
export const toFixed = (n, fixed) => {
  return ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
};

// get work days
export function getWorkDayOf(range) {
  let num = 0;
  if (range && range.length === 2) {
    let d = range[0];
    while (d.isBefore(range[1]) || d.day() === range[1].day()) {
      const day = d.day();
      if (day !== 6 && day !== 0) {
        num++;
      }
      d = d.add(1, 'day');
    }
  }
  return num;
}

export function genLifeCycleDate(data = {}) {
  const { effectiveDateOffset = 10, tenor = 6 } = data;
  let issueDate = '';
  let maturityDate = '';
  let finalValuationDate = '';
  let investmentDays = '';
  const today = dayjs();
  const tradeDate = getNextWorkDate(today);
  if (effectiveDateOffset === 5) {
    issueDate = tradeDate.add(7, 'day');
  } else {
    issueDate = tradeDate.add(14, 'day');
  }
  maturityDate = getNextWorkDate(issueDate.add(+tenor, 'month'));
  finalValuationDate = addWorkDay(maturityDate, 2, -1);
  investmentDays = Math.abs(maturityDate.diff(issueDate, 'day')) + '';
  return {
    issueDate: issueDate.format('YYYY-MM-DD'),
    maturityDate: maturityDate.format('YYYY-MM-DD'),
    finalValuationDate: finalValuationDate.format('YYYY-MM-DD'),
    investmentDays,
  };
}

export function getNextWorkDate(d) {
  const day = d.day();
  let ret = d;
  if (day === 6) {
    ret = d.add(2, 'day');
  } else if (day === 0) {
    ret = d.add(1, 'day');
  }
  return ret;
}

export function addWorkDay(date, workDayNum, direction = 1) {
  let ret = date;
  while (workDayNum > 0) {
    if (direction < 1) {
      ret = ret.subtract(1, 'day');
    } else {
      ret = ret.add(1, 'day');
    }
    const d = ret.day();
    if (d !== 6 && d !== 0) {
      workDayNum--;
    }
  }
  return ret;
}

// download file
export function downloadFile({ data, fileName = '', type, router }) {
  if (!data) return;
  let blob;
  // if (!type) {
  //   type = getFileMimeType(fileName);
  // }
  if (type === 'text/csv') {
    const BOM = '\uFEFF';
    blob = new Blob([BOM + data], { type });
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      return;
    }
  } else {
    blob = new Blob([data], { type });
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      return;
    }
  }
  const href = URL.createObjectURL(blob);
  appOpenPage({
    url: href,
    router,
    // openNew: true,
  });
}

// calc eln yield
export function calcYield(data, force = false) {
  const c = ['HKD', 'GBP', 'SGD', 'AUD', 'TWD', 'CAD'];
  const { currency, issuePrice, investmentDays, uf } = data;
  let { notePrice } = data;
  if (!force) {
    if (!notePrice) return '';
  } else {
    if (!issuePrice) return '';
    notePrice = parseFloat(issuePrice) + parseFloat(uf || 0);
  }
  const day = c.includes(currency) ? 365 : 360;
  const ret = currencyjs(100, { precision: 8 })
    .subtract(+notePrice)
    .divide(+notePrice)
    .multiply(day)
    .divide(+investmentDays || 1)
    .multiply(100);
  return ret.value?.toFixed(2) + '%';
}

// to locale string
export function toLocaleString(data, toFixed = 2) {
  if (isNaN(data)) return data;
  return (+data).toLocaleString(undefined, {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  });
}

// html string tag whitelist
const allowAttr = ['class', 'style'];
const allowAAttr = ['class', 'style', 'href'];
const allowImgAttr = ['class', 'style', 'width', 'height', 'src'];
export const htmlTagWhitelist = {
  div: allowAttr,
  span: allowAttr,
  b: allowAttr,
  i: allowAttr,
  u: allowAttr,
  a: allowAAttr,
  h1: allowAttr,
  h2: allowAttr,
  h3: allowAttr,
  h4: allowAttr,
  h5: allowAttr,
  h6: allowAttr,
  table: allowAttr,
  thead: allowAttr,
  tbody: allowAttr,
  tr: allowAttr,
  th: allowAttr,
  td: allowAttr,
  figure: allowAttr,
  p: allowAttr,
  ul: allowAttr,
  ol: allowAttr,
  li: allowAttr,
  dl: allowAttr,
  dt: allowAttr,
  dd: allowAttr,
  br: allowAttr,
  hr: allowAttr,
  code: allowAttr,
  strong: allowAttr,
  em: allowAttr,
  img: allowImgAttr,
};

export function asyncDebounce(func, wait) {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
}

export function getFilePath(relativePath = '') {
  if (!relativePath.startsWith('/')) {
    relativePath = '/' + relativePath;
  }
  return relativePath;
}

export function getHostUrl(path) {
  // return `https://t-extramile-app.easyview.xyz${path}`;
  if (isClient() && path) {
    return `${window.origin}${path}`;
  }
}

// search and sort
export function searchAndSort(array = [], keys = [], keywords = '') {
  if (!keywords || typeof keywords === 'object') return array;
  keywords = `${keywords}`.toUpperCase();
  const results = [];
  // key match > position > match percent
  // 属性优先匹配>位置>匹配的字符占总字符的百分比
  // 100, 10, 1
  function calcScore(v, keyIndex = -1) {
    let score = 0;
    let index = v.indexOf(keywords);
    if (index > -1) {
      let keyWeightArr = new Array(keys.length).fill('000');
      let matchPercent = Math.round((keywords.length / v.length) * 9);
      if (keyIndex > -1) {
        keyWeightArr[keyIndex] = 100;
        keyWeightArr[keyIndex] += (9 - index) * 10;
        keyWeightArr[keyIndex] += matchPercent;
        let keyWeightArrNum = +keyWeightArr.join('');
        score = keyWeightArrNum; // add key score * match percent
      }
      // score += (9 - index) * 100; // add position score
      // score *= matchPercent;
    }
    return score;
  }
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    let currentWeight = 0;
    if (!item) continue;
    if (typeof item !== 'object') {
      currentWeight = calcScore(`${item}`.toUpperCase());
    } else {
      for (let j = 0; j < keys.length; j++) {
        let v = item[keys[j]] ?? '';
        if (!v) continue;
        v = `${v}`.toUpperCase();
        currentWeight += calcScore(v, j);
      }
    }
    if (currentWeight > 0) {
      results.push({
        data: item,
        weight: currentWeight,
      });
    }
  }
  results.sort((a, b) => {
    return b.weight - a.weight;
  });
  return results.map((d) => d.data);
}

export const solveForKeys = [
  { key: 'strike', display: 'Strike (%)' },
  { key: 'koBarrier', display: 'KO (%)' },
  { key: 'coupon', display: 'Coupon p.a. (%)' },
  { key: 'bonus', display: 'Bonus (%)' },
  { key: 'notePrice', display: 'NotePrice (%)' },
  { key: 'uf', display: 'UF' },
  { key: 'issuePrice', display: 'NotePrice (%)' },
  { key: 'tenor', display: 'Tenor' },
  { key: 'koType', display: 'KO Type' },
  { key: 'barrierType', display: 'Barrier Type' },
  { key: 'kiBarrier', display: 'KI (%)' },
  { key: 'observationFrequency', display: 'Observation Frequency (m)' },
  { key: 'currency', display: 'Currency' },
  { key: 'effectiveDateOffset', display: 'Effective Date offset' },
  { key: 'otc', display: 'OTC' },
  { key: 'fundingSpread', display: 'Funding Spread (bps)' },
  { key: 'issueDate', display: 'Issue Date' },
  { key: 'finalValuationDate', display: 'Final Valuation Date' },
  { key: 'maturityDate', display: 'Maturity Date' },
  { key: 'investmentDays', display: 'Investment Days' },
  { key: 'guaranteedPeriods', display: 'Guaranteed Periods (m)' },
  { key: 'monthlyKoStepDown', display: 'KO Stepdown (%)' },
  { key: 'gpUnit', display: 'gpUnit' },
  { key: 'settlementFrequency', display: 'Settlement Frequency' },
  { key: 'koSettlementCycle', display: 'KO+1 Settlement Cycle' },
  { key: 'normalLeveraged', display: 'Normal/Leveraged' },
  { key: 'grossMarginToClient', display: 'Gross Margin' },
  { key: 'leverageLevel', display: 'Leverage' },
  { key: 'optionType', display: 'Option Type' },
  { key: 'bidPrice', display: 'Bid Price (%)' },
  { key: 'offerPrice', display: 'Offer Price (%)' },
  { key: 'exercise', display: 'Exercise' },
  { key: 'settlementType', display: 'Settlement Type' },
  { key: 'couponType', display: 'Coupon Type' },
  { key: 'couponBarrier', display: 'Coupon Barrier (%)' },
  { key: 'direction', display: 'Direction' },
  { key: 'upperBarrier', display: 'Upper Barrier (%)' },
  { key: 'lowerBarrier', display: 'Lower Barrier (%)' },
  { key: 'cashRebate', display: 'Cash Rebate (%)' },
  { key: 'minimumReturn', display: 'Minimum Return' },
  { key: 'floorCoupon', display: 'Floor Coupon (%)' },
  { key: 'principalProtection', display: 'Principal Protection (%)' },
  { key: 'participation', display: 'Participation Rate (%)' },
  { key: 'format', display: 'Format' },
  { key: 'reofferPrice', display: 'Reoffer Price (%)' },
];

export const add = (...args) => {
  let result;
  if (args.length > 1) {
    result = args.reduce((prev, current) => {
      return prev.plus(current);
    }, new Big(0));
    result = +result.valueOf();
  } else {
    result = args[0];
  }
  return result;
};

export const minus = (...args) => {
  let result;
  if (args.length > 1) {
    const initValue = new Big(args[0]);
    args = args.slice(1);
    result = args.reduce((prev, current) => {
      return prev.minus(current);
    }, initValue);
    result = +result.valueOf();
  } else {
    result = args[0];
  }
  return result;
};

export const times = (...args) => {
  let result;
  if (args.length > 1) {
    result = args.reduce((prev, current) => {
      return prev.times(current);
    }, new Big(1));
    result = +result.valueOf();
  } else {
    result = args[0];
  }
  return result;
};

export const div = (...args) => {
  let result;
  if (args.length > 1) {
    const initValue = new Big(args[0]);
    args = args.slice(1);
    result = args.reduce((prev, current) => {
      return prev.div(current);
    }, initValue);
    result = +result.valueOf();
  } else {
    result = args[0];
  }
  return result;
};

export function isFCN(product) {
  return product === ProductType.PRODUCT_TYPE_FCN;
}
export function isBEN(product) {
  return product === ProductType.PRODUCT_TYPE_BEN;
}
export function isELN(product) {
  return product === ProductType.PRODUCT_TYPE_ELN;
}
export function isWRA(product) {
  return product === ProductType.PRODUCT_TYPE_WRA;
}
export function isStepDownFCN(product) {
  return product === ProductType.PRODUCT_TYPE_STEP_DOWN_FCN;
}
export function isAQ(product) {
  return product === ProductType.PRODUCT_TYPE_AQ;
}
export function isDQ(product) {
  return product === ProductType.PRODUCT_TYPE_DQ;
}
export function isStepDownSCN(product) {
  return product === ProductType.PRODUCT_TYPE_STEP_DOWN_SCN;
}
export function isDCN(product) {
  return product === ProductType.PRODUCT_TYPE_DCN;
}
export function isPhoenix(product) {
  return product === ProductType.PRODUCT_TYPE_PHOENIX;
}
export function isVAN(product) {
  return product === ProductType.PRODUCT_TYPE_VAN;
}
export function isSharkfin(product) {
  return product === ProductType.PRODUCT_TYPE_SHARKFIN;
}
export function isPerformanceParticipation(product) {
  return product === ProductType.PRODUCT_TYPE_PERFORMANCE_PARTICIPATION;
}
export function isSkyline(product) {
  return product === ProductType.PRODUCT_TYPE_SKYLINE;
}
export function isFloater(product) {
  return product === ProductType.PRODUCT_TYPE_FLOATER;
}
export function isFixedFloatingNote(product) {
  return product === ProductType.PRODUCT_TYPE_VANILLA_BOND;
}
export function isOtcProduct(product) {
  switch (product) {
    case ProductType.PRODUCT_TYPE_AQ:
    case ProductType.PRODUCT_TYPE_DQ:
    case ProductType.PRODUCT_TYPE_VAN:
      return true;
    default:
      return false;
  }
}

export function isStructured(product) {
  return product === ProductType.PRODUCT_TYPE_OTHER;
}

// 这八款新产品在交易详情，邮件模块显示用一个模版
export function isNFProduct(product) {
  switch (product) {
    case ProductType.PRODUCT_TYPE_CLN:
    case ProductType.PRODUCT_TYPE_AUTOCALL_BOOSTER:
    case ProductType.PRODUCT_TYPE_BOOSTER:
    case ProductType.PRODUCT_TYPE_DRAN:
    case ProductType.PRODUCT_TYPE_DRACLN:
    case ProductType.PRODUCT_TYPE_LEVERAGED_STEEPENER:
    case ProductType.PRODUCT_TYPE_TRACKER_CERTIFICATE:
    case ProductType.PRODUCT_TYPE_OTHER:
      return true;
    default:
      return false;
  }
}

export const qutationKeyForIndex = {
  1: 'strike',
  2: 'koBarrier',
  3: 'coupon',
  4: 'bonus',
  5: 'notePrice',
  6: 'uf',
  7: 'issuePrice',
  8: 'tenor',
  9: 'koType',
  10: 'barrierType',
  11: 'kiBarrier',
  12: 'observationFrequency',
  13: 'currency',
  14: 'effectiveDateOffset',
  15: 'otc',
  16: 'fundingSpread',
  17: 'issueDate',
  18: 'finalValuationDate',
  19: 'maturityDate',
  20: 'investmentDays',
  21: 'guaranteedPeriods',
  22: 'monthlyKoStepDown',
  23: 'gpUnit',
  24: 'settlementFrequency',
  25: 'koSettlementCycle',
  27: 'normalLeveraged',
  28: 'grossMarginToClient',
  30: 'bidPrice',
  31: 'offerPrice',
};
// decode ws push data
export const decodePbData = (wsData, pbReq) => {
  if (wsData && wsData.data) {
    const buf = protobuf.util.newBuffer(wsData.data);
    const model = getMessage(pbReq);
    const res = model.decode(buf);
    return res;
  }
};

export const getCategoryIds = (categorys, names) => {
  let ids = [];
  if (categorys.length > 0) {
    names.map((n) => {
      categorys.forEach((t) => {
        if (n === t.name) {
          ids.push(t.id);
        }
      });
    });
  }
  return ids;
};

export function getProductName(type) {
  return {
    [ProductType.PRODUCT_TYPE_FCN]: 'FCN',
    [ProductType.PRODUCT_TYPE_BEN]: 'BEN',
    [ProductType.PRODUCT_TYPE_DCN]: 'DCN',
    [ProductType.PRODUCT_TYPE_ELN]: 'ELN',
    [ProductType.PRODUCT_TYPE_WRA]: 'WRA',
    [ProductType.PRODUCT_TYPE_STEP_DOWN_FCN]: 'Step-down FCN',
    [ProductType.PRODUCT_TYPE_AQ]: 'AQ',
    [ProductType.PRODUCT_TYPE_DQ]: 'DQ',
    [ProductType.PRODUCT_TYPE_STEP_DOWN_SCN]: 'Step-down SCN',
    [ProductType.PRODUCT_TYPE_VAN]: 'VAN',
    [ProductType.PRODUCT_TYPE_PHOENIX]: 'Phoenix',
    [ProductType.PRODUCT_TYPE_SHARKFIN]: 'Sharkfin',
    [ProductType.PRODUCT_TYPE_PERFORMANCE_PARTICIPATION]:
      'Performance Participation',
    [ProductType.PRODUCT_TYPE_SKYLINE]: 'Skyline',
    [ProductType.PRODUCT_TYPE_FLOATER]: 'Floater',
    [ProductType.PRODUCT_TYPE_VANILLA_BOND]: 'Fixed/Floating Rate Note',
    [ProductType.PRODUCT_TYPE_CLN]: 'CLN',
    [ProductType.PRODUCT_TYPE_AUTOCALL_BOOSTER]: 'Autocall Booster',
    [ProductType.PRODUCT_TYPE_BOOSTER]: 'Booster',
    [ProductType.PRODUCT_TYPE_DRAN]: 'DRAN',
    [ProductType.PRODUCT_TYPE_DRACLN]: 'DRACLN',
    [ProductType.PRODUCT_TYPE_LEVERAGED_STEEPENER]: 'Leveraged Steepener',
    [ProductType.PRODUCT_TYPE_TRACKER_CERTIFICATE]: 'Tracker Certificate',
    [ProductType.PRODUCT_TYPE_OTHER]: 'Structured Notes(Others)',
  }[type];
}

export function getProductType(name) {
  const productObj = Object.values(products);
  const product = productObj.find((item) => item.name === name) || {};
  return product.type || 0;
}

// 计算DVP reoffer to custodian
export const countDVP = ({
  isELN,
  notePrice,
  issuePrice,
  feeType,
  contractSpread,
}) => {
  let basePrice = isELN ? issuePrice : notePrice;
  if (!feeType || !contractSpread) return '';
  let feeRate = 0;
  if (feeType === 1) {
    // Note Price(%)+ Fee Rate(%);
    feeRate = +contractSpread;
  } else if (feeType === 2) {
    // Fee Rate(%)=（100%-Noteprice（%））*(100%-设置的Fee Rate)
    const s1 = minus(100, +basePrice);
    const s2 = minus(100, +contractSpread);
    const s3 = times(s1, s2)?.toFixed(2);
    feeRate = div(s3, 100);
  }
  return add(+basePrice, +feeRate)?.toFixed(2);
};

export const formatMoney = (num, currency, isInt, fixed = 2) => {
  if (!num) return '--';
  if (isInt) {
    num = parseInt(num).toString();
  } else {
    num = parseFloat(num).toFixed(fixed).toString();
  }
  const re = /\d{1,3}(?=(\d{3})+$)/g;
  const n1 = num.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, '$&,') + s2;
  });
  return currency ? `${n1} ${currency}` : n1;
};

// 压缩图片
export function compressImage(src, options) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const resizeCanvas = document.createElement('canvas');
      const resizeCtx = resizeCanvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      pica()
        .resize(img, resizeCanvas, {
          ...options,
          unsharpAmount: 80,
          unsharpRadius: 0.6,
          unsharpThreshold: 2,
        })
        .then(() => {
          resizeCtx.drawImage(
            resizeCanvas,
            0,
            0,
            resizeCanvas.width,
            resizeCanvas.height
          );
          resizeCanvas.toBlob((blob) => {
            const file = new File([blob], 'image.png', { type: 'image/png' });
            resolve(file);
          }, 'image/png');
        })
        .catch((error) => {
          reject(error);
        });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = src;
  });
}

// 处理金额显示 eg: 1000 = 1K or 1000000 = 1M
export function formatMoney2(money, unit) {
  if (!money) return 0;
  if (+money < 1000) {
    return money;
  } else if (unit === 'K') {
    return `${div(+money, 1000)}${unit}`;
  } else if (unit === 'M') {
    return `${div(+money, 1000000)}${unit}`;
  }
}

// 自动处理金额显示K或M eg: 1000 = 1K or 1000000 = 1M
export function formatMoney3(money, unit) {
  if (!money) return 0;
  if (+money <= 1000) {
    return money;
  } else if (+money > 1000 && +money < 1000000) {
    return `${div(+money, 1000)}K`;
  } else if (+money > 1000000) {
    return `${div(+money, 1000000)}M`;
  } else {
    return money;
  }
}

/**
 * 通过产品名获取相应的产品编号
 * @param {string} params 产品名
 * @param {string} getType 'string'|'number'|underfind
 */
export function getProductPlus(params, getType) {
  const reg = /[^A-Z\d]/gi;
  const getKey = (key) => String(key).replace(reg, '').toLocaleLowerCase();
  const productList = _.mapKeys(ProductType, (v, key) =>
    key.replace(ProductTypePrefix, '').replace(reg, '').toLocaleLowerCase()
  );
  Object.values(productList).forEach((number) => {
    productList[number] = getProductName(number) || '';
  });
  const resultTemp = productList[getKey(params)];
  return getType && typeof resultTemp !== getType
    ? productList[getKey(resultTemp)]
    : resultTemp;
}

/**
 * 获取参数配置
 * @param {number} productType 产品名
 * @param {object} productsConfig
 * @param {isGroup} 'true || false'
 */
export function getProductParamsKey(productType, productsConfig, isGroup) {
  let typeParams = {};
  const product = getProductPlus(productType);
  const configList = _.mapKeys(
    productsConfig,
    (v, key) => getProductPlus(key, 'string') || key
  );
  const { Definition, [product]: Product } = configList;
  const productKey = Object.keys(Product || {});
  productKey.forEach((key) => {
    // 合并配置
    if (
      !Definition[key].uiIgnore &&
      Definition[key].template?.component &&
      !Product[key].onlyView
    ) {
      typeParams[key] = _.mergeWith(
        _.cloneDeep(Definition[key]),
        _.cloneDeep(Product[key]),
        { key: key },
        (obj, src) => {
          // 数组替换，不合并
          if (Array.isArray(obj)) return [...src];
        }
      );
    }
  });
  if (isGroup) {
    return _.groupBy(typeParams, 'tag');
  }
  return typeParams;
}
/**
 * 填充参数的值
 * @param {object} config //
 * @param {data}
 */
export function getProductParamsVal(config, data, t) {
  const evalStr = config.displayScript;

  let tempForm = {};
  Object.keys(data).forEach((tag) => {
    tempForm = { ...tempForm, ...data[tag] };
  });

  if (evalStr) {
    // 如果有展示逻辑，直接调用展示，如 issuer underlying
    result =
      eval(`(context, others)=>{${evalStr}}`)(tempForm, {
        dayjs,
        // addWorkDay,
        // getNextWorkDate,
      }) || '';

    return addSuffix(result, config, tempForm) || '--';
  }
  const value = data[config.tag][config.key];
  let result;
  const type = _.get(config, 'template.component');
  switch (type) {
    case 'SingleUploadField':
      break;
    case 'DateField': // 日期类型
      result = value && dayjs(value).format('YYYY-MM-DD');
      break;
    case 'NumberField': // 数字类型
      result = value ? (+value).toFixed(config.double?.scale || 0) : 0;
      break;
    case 'SelectField': // 下拉选择类型
      const optionsKey = config.enums?.optionsKey || config.key;
      const path = `product.options.${optionsKey}.${value}`;
      const hasTrans = i18n.exists(path);
      result = hasTrans ? t(path) : value ?? '--';
      // if (config.enums?.optionsKey || config.key === 'sourceType') {
      //   // config.enums?.optionsKey
      //   result = value ? t(`product.options.${optionsKey}.${value}`) : '--';
      // } else {
      //   result = value;
      // }
      break;
    default:
      result = value;
      if (/\n/.test(result)) {
        result = result.replaceAll('\n', '<br/>');
      }
      break;
  }

  if (config.key === 'nonCallPeriods' && value === '0') {
    return '--';
  }

  result = addSuffix(result, config, tempForm);
  // if (result && (config.suffix || config.suffixScript)) {
  //   const suffix = config.suffixScript ? eval(`(context, others)=>{${config.suffixScript}}`)(tempForm) : config.suffix;
  //   result += i18n.exists(`product.suffix.${suffix}`) ? i18n.t(`product.suffix.${suffix}`) : suffix;
  // }

  return result || '--';
}

function addSuffix(result, config, tempForm) {
  if (result && (config.suffix || config.suffixScript)) {
    const suffix = config.suffixScript
      ? eval(`(context, others)=>{${config.suffixScript}}`)(tempForm)
      : config.suffix;
    result += i18n.t(`product.suffix.${suffix}`) || suffix;
  }
  return result;
}

// 姓名脱敏处理   张三 => 张* maskSensitiveData(str);
export function maskSensitiveData(str, maskChar = '*') {
  if (typeof str !== 'string' || str.length === 0) return '';
  const visiblePart = str.slice(0, 1); // 保留可见部分
  let last = str.slice(1, str.length - 1);
  if (last.length > 7) {
    last = last.substring(0, 7);
  }
  const maskedPart = last.replace(/./g, maskChar); // 用 maskChar 替换剩余部分
  return visiblePart + maskedPart;
}

export const md5EncryptData = (data) => {
  const psdSalt = 'zxcvbnmasdfghjklqwertyuiop';
  const encryptedData = CryptoJS.MD5(data + psdSalt).toString();
  return encryptedData;
};
